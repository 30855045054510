<template>
  <section id="schedule">
    <div class="mb-2">
      <b-tabs pills class="navigation-tabs mt-1 mt-md-0" nav-class="mb-0">
        <template #tabs-start>
          <b-nav-item
            v-for="(navtab, index) in days"
            :key="index"
            role="presentation"
            :active="index === activeDays"
            class="font-weight-bold"
            @click="navigateTab(index)"
          >
            <span>{{ navtab }}</span>
          </b-nav-item>
        </template>
      </b-tabs>
    </div>
    <b-row v-if="loadSchedule">
      <b-col class="d-flex justify-content-center mt-4">
        <div class="text-center mt-4">
          <b-spinner variant="primary" class="mr-1" />
          <p class="mt-1">
            Memuat Jadwal Kelas
          </p>
        </div>
      </b-col>
    </b-row>

    <b-row v-if="!loadSchedule && selectedScheduleByDay.length > 0">
      <b-col
        v-for="activeSchedule in selectedScheduleByDay"
        :key="activeSchedule.id"
        xl="4"
        sm="6"
      >
        <class-card
          variant="enrolled"
          :classroom-data="activeSchedule.classroom"
        />
      </b-col>
    </b-row>

    <b-row v-if="!loadSchedule && selectedScheduleByDay.length === 0">
      <b-col class="d-flex justify-content-center mt-4">
        <div class="text-center pt-2">
          <b-img class="illustration-small" fluid :src="emptyState" />
          <p class="mb-2">
            Anda tidak mempunyai jadwal hari ini
          </p>
          <b-button
            variant="primary"
            class="mb-1 btn-sm-block"
            :to="{
              name: checkAuthorizeRole('classroom'),
            }"
            >Gabung Kelas Baru</b-button
          >
        </div>
      </b-col>
    </b-row>
  </section>
</template>

<script>
// * Utils
import client from '@/libs/http/axios-config'
import { checkAuthorizeRole } from '@/auth/utils'

// * Components
import {
  BRow,
  BCol,
  BTabs,
  BNavItem,
  BImg,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import ClassCard from '@core/layouts/components/card/class-card/ClassCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BNavItem,
    ClassCard,
    BImg,
    BButton,
    BSpinner,
  },
  data() {
    return {
      loadSchedule: true,
      emptyState: require('@/assets/images/illustration/empty-schedule-state.svg'),
      activeDays: new Date().getDay() - 1,
      days: [],
      schedules: [],
    }
  },
  computed: {
    selectedScheduleByDay() {
      let tempSchedules = this.schedules

      tempSchedules = tempSchedules.filter(
        (scheduleItem) =>
          scheduleItem.classroom.schedule === this.days[this.activeDays]
      )

      if (tempSchedules.length > 0) {
        return tempSchedules
      }
      return []
    },
  },
  async created() {
    // Get taken classroom
    await client.get('/students/schedules').then((response) => {
      this.schedules = response.data.data
    })

    // mapping all classroom
    this.schedules.map((schedule) => {
      schedule.classroom.isTaken = true
      schedule.classroom.pivotStudentClassroomId = schedule.id
    })

    this.loadSchedule = false
  },
  mounted() {
    // get week days
    function getWeek() {
      const locale = 'id-ID'
      const baseDate = new Date(Date.UTC(2022, 9, 24)) // just a Monday
      const weekDays = []
      for (let i = 0; i < 7; i += 1) {
        weekDays.push(baseDate.toLocaleDateString(locale, { weekday: 'long' }))
        baseDate.setDate(baseDate.getDate() + 1)
      }

      return weekDays
    }

    this.days = getWeek()
  },
  methods: {
    navigateTab(index) {
      this.activeDays = index
    },
    checkAuthorizeRole,
  },
}
</script>
